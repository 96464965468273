// i18next-extract-mark-ns-start visa-mastercard-for-wix
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_visamastercard from 'images/wix_visa-mastercard.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {PageContext} from 'gatsby/internal';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC<PageContext> = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="visa-mastercard-for-wix"
        title={t('Wix Credit Card Payments')}
        description={t(
          'Accept Wix credit card payments with MONEI’s e-commerce payment gateway. Including Visa, Mastercard, JCB, Diners, and UnionPay. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Add Wix credit card payments to your online store</Trans>
              </SectionHeader>
              <Trans parent="p">
                You’ve picked your Wix theme, created product pages, and now it’s time to add credit
                card payments to Wix. But to do this, you need a payment gateway that supports Wix
                credit card payments.{' '}
              </Trans>
              <Trans parent="p">
                That’s where we come in. Our advanced e-commerce payment gateway lets you accept and
                manage all the most popular payment methods in a single platform. Open an account
                today to get started with Wix credit card payments. Supported card networks include
                Visa, Mastercard, JCB, Diners, and UnionPay.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={wix_visamastercard}
              alt="Wix visa mastercard"
              title="Wix visa mastercard"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Accept credit card payments and alternative payment methods in your Wix store
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting credit card payments in your Wix store is essential. But there are also{' '}
                <BlogLink slug="alternative-payment-methods">
                  many alternative payment methods
                </BlogLink>{' '}
                consumers look for at checkout. This includes{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> such as{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , plus <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                Adding more payment options to your Wix store will help you reach more people, boost
                customer satisfaction, and increase your conversion rate. That’s why we make it easy
                for you to manage a wide range of online payment methods from a single merchant
                dashboard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Increase sales by approving more transactions</Trans>
              </SectionHeader>
              <Trans parent="p">
                Depending on <Link to="/redsys-alternative/">Redsys</Link> alone as your payment
                processor can lead to false{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                and lost sales. Provide a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience,
                approve more payments, and increase sales thanks to our{' '}
                <Link to="/features/payments-orchestration/">payments orchestration</Link> feature.
                Set up payment processing rules by choosing a default processor and if it’s
                experiencing downtime, we’ll route the payment to a different, better performing
                provider.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the best Wix payment gateway transaction fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                We want to help you grow your e-commerce business faster. That’s why we’re the only
                payment gateway to offer scaled pricing — as you sell more, your transaction fees
                will decrease in real-time. This way, you’ll save more money to reinvest in your
                store.
              </Trans>
              <Trans parent="p">
                Choose a dynamic Wix payment gateway that scales with your business —{' '}
                <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to enable Wix credit card payments</Trans>
            </SectionHeader>
            <Trans parent="p">
              Follow these steps to connect your website with our payment gateway, and start
              accepting Wix credit card payments (and more) today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/sections/360005119117">
                  Configure credit card payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration
                </AnchorLink>{' '}
                guide to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4406122571025">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "visa-mastercard-for-wix"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
